<template>
  <CFooter :fixed="false">
    <div>
      <a href="" target="_blank">Earning App</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Earning BD.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="">{{ $t('sidebar.dashboard') }} Earning BD</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
